














































































































































import Vue from 'vue';
import { getUser } from '@/helper/handle-auth';
import { AuthResponse } from '@/model/auth';
import apiBackend from '../service/api-insurance';


export default Vue.extend({
  name: 'offers',
  data: () => ({
    insurances: [],
    letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Alle'],
    draw: 0,
    search: '',
    start: 0,
    length: 5,
    recordsTotal: 0,
    recordsFiltered: 0,
    letter: '',
    sortBy: '',
    sortType: 'DESC',
    searchByField: '',
    searchByFieldValue: '',
    idSearchValue: '',
    offerNumber: '',
    customerName: '',
    processNameSearchValue: '',
    userSearchValue: '',
  }),
  mounted() {
    apiBackend.fetch(
      this.draw,
      this.search,
      this.length,
      this.start,
      this.letter,
      this.sortBy,
      this.sortType,
      this.idSearchValue,
      this.processNameSearchValue,
      this.userSearchValue,
      this.offerNumber,
      this.customerName,
    ).then((response) => {
      this.insurances = response.data.data;
      this.recordsTotal = response.data.recordsTotal;
      this.recordsFiltered = response.data.recordsFiltered;
    });
    this.$emit('onId', '');
    this.$emit('getCustomerData', '');
  },
  watch: {
    length() {
      this.draw = 0;
      this.start = 0;
      this.sendRequest();
    },
    search() {
      this.draw = 0;
      this.start = 0;
      this.sendRequest();
    },
  },
  computed: {
    user(): AuthResponse | null {
      return getUser();
    },
    pages() {
      return this.recordsFiltered / this.length - 1;
    },
    roles() {
      const user: AuthResponse | null = getUser();

      return user?.roles || [];
    },
    isSuperAdmin(): boolean {
      return this.roles.indexOf('super-admin') > -1;
    },
  },
  methods: {
    redirectToRoute(id: number) {
      const path = `get-insurance/${id}`;
      this.$router.push({
        path,
      }).catch((err) => {
        throw new Error(`Problem handling something: ${err}.`);
      });
    },
    sendRequest() {
      apiBackend.fetch(
        this.draw,
        this.search,
        this.length,
        this.start,
        this.letter,
        this.sortBy,
        this.sortType,
        this.idSearchValue,
        this.processNameSearchValue,
        this.userSearchValue,
        this.offerNumber,
        this.customerName,
      ).then((response) => {
        this.insurances = response.data.data;
        this.recordsTotal = response.data.recordsTotal;
        this.recordsFiltered = response.data.recordsFiltered;
      });
    },
    nextPage() {
      this.draw += 1;
      this.start = this.draw * this.length;
      this.sendRequest();
    },
    prevPage() {
      this.draw -= 1;
      this.start = this.draw * this.length;
      this.sendRequest();
    },
    letterClick(currentLetter: string) {
      if (currentLetter !== 'All') {
        this.letter = currentLetter;
      } else {
        this.letter = '';
      }
      this.draw = 0;
      this.start = 0;
      this.sendRequest();
    },
    exportClick() {
      apiBackend.exportToExcel().then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'all_Insurances.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        // const { message } = response.data;
        // Vue.toasted.show(message, {
        //   type: 'success',
        // });
      });
    },
    orderBy(column: string) {
      if (this.sortBy === column) {
        this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.sortType = 'ASC';
      }
      this.sortBy = column;
      this.sendRequest();
    },
  },
});
